var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"manage-developer-bank-accounts container fluid"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"d-flex align-items-center p-2 mb-4"},[_c('fd-button',{staticClass:"circle mr-1",on:{"click":_vm.goBack}},[_c('i',{staticClass:"fas fa-chevron-left"})]),_c('h2',[_vm._v("Manage Developer Bank Account")])],1),_c('h4',{staticClass:"p-2"},[_vm._v("Bank Accounts")]),_c('vue-good-table',{attrs:{"mode":"remote","columns":_vm.bankAccountTableColumns,"rows":_vm.bankAccountTableData,"totalRows":_vm.bankAccountTablePagination.total,"pagination-options":{
        enabled: true,
        mode: 'pages',
        perPage: 30,
        perPageDropdown: _vm.perPageOptions,
        dropdownAllowAll: false
      },"sort-options":{
        enabled: false
      }},on:{"on-page-change":_vm.onPageChange,"on-per-page-change":_vm.onPerPageChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'actions')?_c('span',[_c('fd-button',{staticClass:"main bordered mr-1",on:{"click":function($event){return _vm.openBankAccountModal(true, props.row)}}},[_c('i',{staticClass:"fas fa-pen"})]),_c('fd-button',{staticClass:"danger bordered",on:{"click":function($event){return _vm.deleteBankAccount(props.row.id)}}},[_c('i',{staticClass:"fas fa-trash"})])],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[_c('no-data',{attrs:{"message":"There is no data"}})],1),_c('div',{staticClass:"p-2",attrs:{"slot":"table-actions"},slot:"table-actions"},[_c('fd-button',{staticClass:"main mr-2",on:{"click":function($event){return _vm.openBankAccountModal()}}},[_c('i',{staticClass:"fas fa-plus"})])],1)])],1),_c('modal',{model:{value:(_vm.bankAccountModal.isShown),callback:function ($$v) {_vm.$set(_vm.bankAccountModal, "isShown", $$v)},expression:"bankAccountModal.isShown"}},[_c('bank-account',{attrs:{"isEdit":_vm.bankAccountModal.isEdit,"developerId":_vm.$route.params.id,"bankAccount":_vm.bankAccountModal.bankAccountToEdit},on:{"cancel":function($event){_vm.bankAccountModal.isShown = false},"create":_vm.addBankAccount,"update":_vm.updateBankAccount}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }